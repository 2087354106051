<template>
  <div :style="`--text-transform: ${transform}`" class="form-group-subtitle">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'FormGroupSubtitle',
  props: {
    transform: {
      type: String,
      default: 'none',
    },
  },
};
</script>

<style lang="scss" scoped>
.form-group-subtitle {
  color: $text-semi-dark;
  font-size: 14px;
  line-height: 1.14;
  font-weight: 400;
  text-transform: var(--text-transform);
}
</style>

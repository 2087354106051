<template>
  <div :class="{ 'form-section--row': row }" class="form-section">
    <div class="form-section__header">
      <slot name="header">
        <FormSectionTitle :dark="darkTitle" :required="required" :transform="titleTransform">
          <slot name="title">
            <span v-if="capitalizeTitle">
              {{ title | capitalize }}
            </span>
            <span v-else>
              {{ title }}
            </span>
          </slot>
        </FormSectionTitle>
      </slot>
    </div>
    <div class="form-section__body">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import FormSectionTitle from '@/components/typography/FormSectionTitle';

export default {
  name: 'FormSection',
  components: {
    FormSectionTitle,
  },
  props: {
    capitalizeTitle: Boolean,
    darkTitle: Boolean,
    title: String,
    required: Boolean,
    row: Boolean,
    titleTransform: {
      type: String,
      default: 'none',
    },
  },
};
</script>

<style lang="scss" scoped>
.form-section {
  display: flex;
  flex-direction: column;
  width: 100%;
  &--row {
    flex-direction: row;
    align-items: flex-end;
  }
}
.form-section__body {
  padding-top: 1rem;
}
</style>

<template>
  <h5
    :style="`--text-transform: ${transform}; font-weight: ${fontWeight}; font-size: ${fontSize}`"
    :class="{ 'form-group-title--customSize': fontSize !== undefined }"
    class="form-group-title"
  >
    <slot></slot>
  </h5>
</template>

<script>
export default {
  name: 'FormGroupTitle',
  props: {
    transform: {
      type: String,
      default: 'none',
    },
    fontWeight: {
      type: [String, Number],
      default: () => '500',
    },
    fontSize: {
      type: [String, Number],
    },
  },
};
</script>

<style lang="scss" scoped>
.form-group-title {
  color: $text-darkest;
  font-size: 1.4rem;
  line-height: 1.16;
  text-transform: var(--text-transform);
}
</style>

<template>
  <section class="form-group">
    <div class="form-group__header">
      <slot name="header">
        <FormGroupTitle
          :transform="titleTransform"
          :font-size="titleSize"
          :font-weight="titleWeight"
        >
          {{ title }}
        </FormGroupTitle>
        <div
          v-if="subtitle"
          class="form-group__header-subtitle-wrapper mt-3"
          :class="{ 'mb-5': !noSubtitleBottomMargin }"
        >
          <FormGroupSubtitle>
            {{ subtitle }}
          </FormGroupSubtitle>
        </div>
      </slot>
    </div>
    <div class="form-group__body">
      <slot></slot>
    </div>
  </section>
</template>

<script>
import FormGroupTitle from '@/components/typography/FormGroupTitle';
import FormGroupSubtitle from '@/components/typography/FormGroupSubtitle';

export default {
  name: 'FormGroup',
  props: {
    noSubtitleBottomMargin: Boolean,
    title: String,
    titleWeight: {
      type: [String, Number],
      default: () => '500',
    },
    titleSize: {
      type: [String, Number],
    },
    titleTransform: {
      type: String,
      default: 'uppercase',
    },
    subtitle: String,
  },
  components: {
    FormGroupTitle,
    FormGroupSubtitle,
  },
};
</script>

<style lang="scss">
.form-group__body {
  padding-top: 1.5rem;
  .row {
    & > * {
      padding-top: 0px;
      padding-bottom: 0px;
    }
  }
}
</style>

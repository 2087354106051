<template>
  <v-menu
    v-model="open"
    :close-on-content-click="false"
    :attach="attach"
    :max-width="maxMenuWidth"
    class="ph-timepicker-menu"
    transition="scale-transition"
    offset-y
  >
    <template v-slot:activator="{ on }">
      <div v-on="on" :style="customStyle" class="ph-timepicker">
        <v-text-field
          :label="label"
          :value="value"
          :rules="required ? ['required'] : []"
          :hide-details="hideDetails"
          :placeholder="placeholder"
          :full-width="fullWidth"
          autocomplete="new-password"
          class="ph-timepicker__text-field"
          :rounded="rounded"
          outlined
          readonly
          solo
        >
          <span>{{ value }}</span>
          <template v-slot:append>
            <SvgIcon icon="clock" class="ph-timepicker__icon" />
          </template>
        </v-text-field>
      </div>
    </template>
    <v-time-picker @input="handleInput" :allowed-dates="allowedTimes" :value="value">
      <v-spacer />
      <v-btn
        @click="
          open = false;
          handleInput('');
        "
        color="primary"
        text
      >
        Cancel
      </v-btn>
      <v-btn @click="open = false" color="primary" text>
        Ok
      </v-btn>
    </v-time-picker>
  </v-menu>
</template>

<script>
import { dateTimePickerProperties } from '@/mixins';
import dateValidator from '@/utils/helpers/datetime/dateValidator';

export default {
  name: 'PhTimepicker',
  mixins: [dateTimePickerProperties],
  props: {
    allowedTimesFunc: Function,
  },
  methods: {
    allowedTimes(val) {
      if (typeof this.allowedTimesFunc === 'function') {
        return this.allowedTimesFunc(val);
      }
      if (this.minDate && this.maxDate) {
        return this.allowedTimesInRange(val);
      }
      return true;
    },
    allowedTimesInRange(val) {
      return dateValidator.range(val, this.minDate, this.maxDate);
    },
  },
};
</script>

<style lang="scss">
.ph-timepicker-menu {
  .v-picker {
    .v-picker__body {
      margin-left: 0px;
      margin-right: 0px;
    }
  }
}
.ph-timepicker {
  display: inline-block;
  min-width: 165px;
  .ph-timepicker__icon {
    svg {
      path {
        fill: var(--v-accent-base);
      }
    }
  }
  .ph-timepicker__text-field {
    &.v-text-field {
      &--solo {
        &:not(.v-text-field--solo-flat) {
          & > .v-input__control {
            & > .v-input__slot {
              box-shadow: none;
              padding: 0 16px;
              .v-input__prepend-inner {
                padding-right: 16px;
              }
              .v-text-field__slot {
                font-size: 1rem;
                font-weight: 500;
                & > input::placeholder {
                  font-weight: 400;
                }
              }
            }
          }
        }
      }
      &.theme--light {
        &:not(.v-input--is-focused) {
          &.v-text-field--rounded {
            & > .v-input__control {
              & > .v-input__slot {
                background: $background-lightest;
                & > fieldset {
                  border-color: $background-lightest;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>

<template>
  <SvgIcon icon="asterix" class="required-asterix" />
</template>

<script>
export default {
  name: 'RequiredAsterix',
};
</script>

<style lang="scss" scoped>
$size: 6px;
.required-asterix {
  display: inline-block;
  width: $size;
  height: $size;
  & > svg {
    width: $size;
    height: $size;
  }
}
</style>

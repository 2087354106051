<template>
  <div
    :style="`--text-transform: ${transform}`"
    :class="{
      'form-section-title--block': block,
      'form-section-title--dark': dark,
      'form-section-title--required': required,
    }"
    class="form-section-title"
  >
    <div class="form-section-title__content">
      <slot></slot>
      <div v-if="required" class="form-section-title__asterix-wrapper">
        <RequiredAsterix />
      </div>
    </div>
  </div>
</template>

<script>
import RequiredAsterix from '@/components/typography/RequiredAsterix';

export default {
  name: 'FormSectionTitle',
  components: {
    RequiredAsterix,
  },
  props: {
    dark: Boolean,
    block: {
      type: Boolean,
      default: true,
    },
    required: Boolean,
    transform: {
      type: String,
      default: 'none',
    },
  },
};
</script>

<style lang="scss" scoped>
.form-section-title {
  display: inline-block;
  color: $text-semi-dark;
  font-size: 0.933rem;
  line-height: 1.14;
  font-weight: 500;
  text-transform: var(--text-transform);
  &--block {
    display: block;
  }
  &--dark {
    color: $text-darkest;
  }
  &--required {
    padding-right: 10px;
    .form-section-title__content {
      position: relative;
    }
  }
}
.form-section-title__content {
  display: inline-block;
}
.form-section-title__asterix-wrapper {
  position: absolute;
  top: 0px;
  right: 0px;
  transform: translate(120%, -50%);
}
</style>

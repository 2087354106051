import { VRadioGroup } from 'vuetify/lib';
import getDefaultValidationProps from '@/utils/helpers/vuetify/getDefaultValidationProps';

export default (component => ({
  functional: true,
  name: 'VRadioGroup',
  render(h, context) {
    return h(
      component,
      {
        ...context.data,
        props: {
          ...getDefaultValidationProps(context),
        },
      },
      context.children
    );
  },
}))(VRadioGroup);
